<template>
    <div class="animated fadeIn">
        <div>
            <b-card>
                <div slot="header">
                    <strong style="margin-left: 5px">Setup Your Project</strong>
                </div>

                <div role="tablist" class="tabbed-form">
                    <b-card no-body class="mb-1">
                        <b-card-header @click="toggleStep(0)" header-tag="header" class="p-1 form-step-name" role="tab">
                            <img src="../../../assets/gcp-icons/step1.png" />
                            {{ tabs[0] }}
                        </b-card-header>
                        <b-collapse v-model="tabOpen[0]" visible role="tabpanel">
                            <b-card-body>
                                <b-form class="step-form">
                                    <b-form-group
                                        v-if="project.type !== 'SANDBOX' && project.role.toLowerCase() === 'owner'"
                                        label="Billing account"
                                        label-for="project-billing-account"
                                        :horizontal="true"
                                        :label-cols="2"
                                    >
                                        <b-row>
                                            <b-col md="8">
                                                <wit-select
                                                    :options="this.$store.state.billing.accounts"
                                                    v-model="billingAccount"
                                                    placeholder="Select billing account"
                                                    disabled
                                                    id="project-billing-account"
                                                    label="name"
                                                ></wit-select>
                                            </b-col>
                                        </b-row>
                                    </b-form-group>

                                    <b-form-group label="Project name" :label-cols="2" :horizontal="true">
                                        <b-row>
                                            <b-col md="8">
                                                <b-form-input
                                                    v-model="project.name"
                                                    type="text"
                                                    name="Project name"
                                                    placeholder="Enter your project name"
                                                    disabled
                                                ></b-form-input>
                                            </b-col>
                                        </b-row>
                                    </b-form-group>

                                    <b-form-group
                                        label="Select Google Cloud Project"
                                        label-for="biguqery-projects"
                                        :label-cols="2"
                                        :horizontal="true"
                                    >
                                        <b-row>
                                            <b-col md="8">
                                                <wit-select
                                                    :options="[project.projectId]"
                                                    v-model="project.projectId"
                                                    disabled
                                                    placeholder="Select project"
                                                ></wit-select>
                                            </b-col>
                                        </b-row>
                                    </b-form-group>

                                    <b-form-group
                                        label="Select Google Cloud Project Location"
                                        label-for="biguqery-project-location"
                                        :horizontal="true"
                                        :label-cols="2"
                                    >
                                        <b-row>
                                            <b-col md="8">
                                                <wit-select
                                                    :options="[this.$store.state.bigquery.locations]"
                                                    v-model="location"
                                                    placeholder="Select location"
                                                    :custom-label="prettyLocation"
                                                    disabled
                                                ></wit-select>
                                            </b-col>
                                        </b-row>
                                    </b-form-group>

                                    <b-form-group label="Dedicated Service Account" :label-cols="2" :horizontal="true">
                                        <b-row>
                                            <b-col md="8">
                                                <copy-text v-model="serviceAccount" />
                                            </b-col>
                                        </b-row>
                                    </b-form-group>

                                    <b-form-group label="Witcloud Dataset URL" :label-cols="2" :horizontal="true">
                                        <b-row>
                                            <b-col md="8">
                                                <URL v-model="datasetUrl" />
                                            </b-col>
                                        </b-row>
                                    </b-form-group>

                                    <b-form-group label="Witcloud Bucket URL" :label-cols="2" :horizontal="true">
                                        <b-row>
                                            <b-col md="8">
                                                <URL v-model="bucketUrl" />
                                            </b-col>
                                        </b-row>
                                    </b-form-group>

                                    <Feedback :state="projectError.state" :invalid="projectError.message"></Feedback>

                                    <b-row>
                                        <b-col>
                                            <project-router-link to="/iam/projects">
                                                <b-button variant="primary">
                                                    <i class="icon-check"></i> Finish
                                                </b-button></project-router-link
                                            >
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </div>
            </b-card>
        </div>
        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

import CopyText from '@/components/CopyText.vue'
import Loading from '@/components/loading.vue'
import Feedback from '@/components/Feedback'
import SelectGoogleToken from '@/components/Tokens/Selects/SelectGoogleToken.vue'
import URL from '@/components/URL.vue'

export default {
    data() {
        return {
            tabs: ['Project settings'],
            tabIndex: 0,
            location: null,
            projectError: {
                state: true,
                message: '',
            },
            project: null,
            serviceAccount: null,
            billingAccount: null,
        }
    },
    computed: {
        ...mapGetters({
            projects: 'project/projects',
            activeProject: 'project/active',
        }),
        tabOpen() {
            return this.tabs.map((tab, index) => (index === this.tabIndex ? true : false))
        },
        datasetUrl() {
            return `https://console.cloud.google.com/bigquery?project=${this.project.projectId}&p=${
                this.project.projectId
            }&d=witcloud_${this.project.id.toLowerCase()}&page=dataset`
        },
        bucketUrl() {
            return `https://console.cloud.google.com/storage/browser/witcloud-st-${this.project.id.toLowerCase()}?forceOnBucketsSortingFiltering=false&project=${
                this.project.projectId
            }`
        },
    },
    components: {
        CopyText,
        Loading,
        Feedback,
        SelectGoogleToken,
        URL,
    },
    async created() {
        this.$store.commit('loading/PROCESSING', `Preparing Your Project...`)
        this.checkPage = this.tabs.map(el => false)
        await this.prepareProject()
        this.$store.commit('loading/PROCESSED')
    },
    methods: {
        async prepareProject() {
            this.project = this.projects.find(el => el.id === this.$route.params.id)
            const locations = await this.$store.dispatch('bigquery/getLocations')
            this.location = locations.find(el => el.name === this.project.location)
            const response = await this.axios.get(
                `${process.env.VUE_APP_NODE_API_HOST}/serviceAccounts/main?projectId=${this.project.id}`
            )
            this.serviceAccount = response.data.data.serviceAccountData.email

            if (this.project.type !== 'SANDBOX' && this.project.role.toLowerCase() === 'owner') {
                await this.$store.dispatch('billing/getAccounts')
                this.billingAccount = this.$store.state.billing.accounts.find(
                    el => el.id === this.project.billingAccountRef.id
                )
            }
        },

        toggleStep(step) {
            this.tabIndex = step
            this.$forceUpdate()
        },

        prettyLocation({name, description}) {
            return `${name} (${description})`
        },
    },
}
</script>

<style scoped>
.close-button {
    font-size: 1.5rem;
}
</style>
